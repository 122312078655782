import axios from "axios";

const axiosInstance = axios.create({
  baseURL: "https://api.jisuqipai.online/",
});

// 登录接口
export const Login = (account, password) => {
  return axiosInstance.post("/api/user/login", {
    account: account,
    password: password,
  });
};

// 注册接口
export const Register = (username, password, email, captcha) => {
  return axiosInstance.post("/api/user/register", {
    username: username,
    password: password,
    email: email,
    captcha: captcha,
  });
};

// usdt支付接口
export const usdtPay = (amount, token) => {
  return axiosInstance.post(
    "/api/payment/usdt",
    {
      amount: amount,
    },
    {
      headers: {
        token: token,
      },
    }
  );
};

// 获取文章列表
export const getArticles = () => {
  return axiosInstance.get("/api/index/articles");
};

// 获取对应id文章信息
export const getArticleDetails = (id) => {
  return axiosInstance.post("/api/index/articleDetails", {
    id: id,
  });
};

// 获取邮箱验证码
export const getMailCaptcha = (email, event) => {
  return axiosInstance.post("/api/ems/send", {
    email: email,
    event: event,
  });
};

// 通过邮箱找回密码
export const resetpwd = (email, newpassword, captcha) => {
  return axiosInstance.post("/api/user/resetpwd", {
    email: email,
    newpassword: newpassword,
    captcha: captcha,
  });
};

// YonoGames上分体验
export const YonoUpscore = (uid, coin, token) => {
  return axiosInstance.post(
    "/api/demo/YonoUpscore",
    {
      uid: uid,
      coin: coin,
    },
    {
      headers: {
        token: token,
      },
    }
  );
};

// 日活月活访问统计
export const statistics = () => {
  return axiosInstance.post("/api/index/statistics");
};

// 代理登录接口
export const AgentLogin = (account, password) => {
  return axiosInstance.post("/api/agent/login", {
    account: account,
    password: password,
  });
};

// 代理创建用户
export const createAgent = (token, username) => {
  return axiosInstance.post(
    "/api/agent/createAgent",
    {
      username: username,
    },
    {
      headers: {
        token: token,
      },
    }
  );
};

// 代理重置密码
export const resetPwd = (token, username, oldPassword, newPassword) => {
  return axiosInstance.post(
    "/api/agent/resetPwd",
    {
      username: username,
      oldPassword: oldPassword,
      newPassword: newPassword,
    },
    {
      headers: {
        token: token,
      },
    }
  );
};

// 通过代理链接创建用户
export const registerForUid = (
  username,
  password,
  email,
  captcha,
  inviteid
) => {
  return axiosInstance.post("/api/user/registerForUid", {
    username: username,
    password: password,
    email: email,
    captcha: captcha,
    inviteid: inviteid,
  });
};

// 获取当前代理用户直接客户数量 间接客户数量 今日新增客户 今日佣金 当月新增直接客户 当月佣金
export const getAgentUserInfo = (token, uid) => {
  return axiosInstance.post(
    "/api/agent/getAgentUserInfo",
    {
      uid: uid,
    },
    {
      headers: {
        token: token,
      },
    }
  );
};

// 代理获取当前uid下所有客户信息
export const getClientInfo = (token, userid, uid, time) => {
  return axiosInstance.post(
    "/api/agent/getClientInfo",
    {
      userid: userid,
      uid: uid,
      time: time,
    },
    {
      headers: {
        token: token,
      },
    }
  );
};

// 获取所有充值记录
export const getrechargelist = (date) => {
  return axiosInstance.post("/api/agent/getrechargelist", {
    date: date,
  });
};

// 代理升级
export const agentUpgrade = (token, uid, level, type) => {
  return axiosInstance.post("/api/agent/agentUpgrade", {
    token: token,
    uid: uid,
    level: level,
    type: type,
  });
};

// 代理获取当前用户的USDT余额
export const getAgentUSDT = (uid) => {
  return axiosInstance.post("/api/agent/getAgentUSDT", {
    uid: uid,
  });
};

// 提交用户定制信息(游戏换皮)
export const gamechange = (game, type, contact, info, img, uid) => {
  return axiosInstance.post("/api/index/gamechange", {
    game: game,
    type: type,
    contact: contact,
    info: info,
    img: img,
    uid: uid,
  });
};

// 提交用户定制信息(二次开发)
export const secondChange = (game, contact, info, img, uid) => {
  return axiosInstance.post("/api/index/secondChange", {
    game: game,
    contact: contact,
    info: info,
    img: img,
    uid: uid,
  });
};

// 提交用户定制信息(外包维护)
export const fixedGame = (game, tec, contact, info, img, uid) => {
  return axiosInstance.post("/api/index/fixedGame", {
    game: game,
    tec: tec,
    contact: contact,
    info: info,
    img: img,
    uid: uid,
  });
};

// 通过UID获取当前代理的telegram与qq联系信息
export const getContact = (uid) => {
  return axiosInstance.post("/api/agent/getContact", {
    uid: uid,
  });
};

// 通过UID完善当前代理的telegram与qq联系信息
export const updateContact = (uid, telegram, qq) => {
  return axiosInstance.post("/api/agent/updateContact", {
    uid: uid,
    telegram: telegram,
    qq: qq,
  });
};

// 检查当前用户是否存在上级 如果有返回上级telegram和qq
export const checkUpgrade = (username) => {
  return axiosInstance.post("/api/index/checkUpgrade", {
    username: username,
  });
};

// 根据当前用户名获取对应用户uid
export const getUserUid = (token, username) => {
  return axiosInstance.post(
    "/api/user/getUserUid",
    {
      username: username,
    },
    {
      headers: {
        token: token,
      },
    }
  );
};

// 获取游戏换皮数据
export const getGameChange = (uid) => {
  return axiosInstance.post("/api/agent/getGameChange", {
    uid: uid,
  });
};

// 完善游戏换皮报价
export const fillPrice = (id, price) => {
  return axiosInstance.post("/api/agent/fillPrice", {
    id: id,
    price: price,
  });
};

// 获取二次开发数据
export const getSecondDev = (uid) => {
  return axiosInstance.post("/api/agent/getSecondDev", {
    uid: uid,
  });
};

// 填写二次开发报价
export const fillSecondPrice = (id, price) => {
  return axiosInstance.post("/api/agent/fillSecondPrice", {
    id: id,
    price: price,
  });
};

// 获取外包维护数据
export const getOutService = (uid) => {
  return axiosInstance.post("/api/agent/getOutService", {
    uid: uid,
  });
};

// 填写外包维护报价
export const fillOutPrice = (id, price) => {
  return axiosInstance.post("/api/agent/fillOutPrice", {
    id: id,
    price: price,
  });
};

// 服务器下单
export const serverOrder = (
  token,
  uid,
  region,
  memory,
  cpu,
  network,
  disk,
  system,
  username,
  password
) => {
  return axiosInstance.post(
    "/api/user/serverOrder",
    {
      uid: uid,
      region: region,
      memory: memory,
      cpu: cpu,
      network: network,
      disk: disk,
      system: system,
      username: username,
      password: password,
    },
    {
      headers: {
        token: token,
      },
    }
  );
};

// 根据当前用户名获取对应用户uid
export const index = (token) => {
  return axiosInstance.post(
    "/api/user/index",
    {},
    {
      headers: {
        token: token,
      },
    }
  );
};

// 获取极速学院所有分类
export const getSchoolType = () => {
  return axiosInstance.post("/api/school/getSchoolType");
};

// 根据分类名称获取极速学院视频
export const getSchoolVideoList = (type) => {
  return axiosInstance.post("/api/school/getSchoolVideoList", {
    type: type,
  });
};

// 举报
export const report = (token, uid, telegram, wechat, qq, content, img) => {
  return axiosInstance.post(
    "/api/user/report",
    {
      uid: uid,
      telegram: telegram,
      wechat: wechat,
      qq: qq,
      content: content,
      img: img,
    },
    {
      headers: {
        token: token,
      },
    }
  );
};

// 图片上传
export const uploadImg = (img, config) => {
  return axiosInstance.post(
    "/api/user/uploadImg",
    {
      img: img,
    },
    config
  );
};

// 举报
export const getAmount = (token, uid) => {
  return axiosInstance.post(
    "/api/user/getAmount",
    {
      uid: uid,
    },
    {
      headers: {
        token: token,
      },
    }
  );
};

// 设置新头像
export const setNewAvatar = (token, uid, avatar) => {
  return axiosInstance.post(
    "/api/user/setNewAvatar",
    {
      uid: uid,
      avatar: avatar,
    },
    {
      headers: {
        token: token,
      },
    }
  );
};

// 获取USDT最新汇率
export const getUSDTrate = () => {
  return axiosInstance.post("/api/user/getUSDTrate", {});
};

// 检查当前用户状态
export const checkUserStatus = (token, uid) => {
  return axiosInstance.post(
    "/api/user/checkUserStatus",
    {
      uid: uid,
    },
    {
      headers: {
        token: token,
      },
    }
  );
};

// 根据视频id获取极速学院视频
export const getSchoolVideo = (id) => {
  return axiosInstance.post("/api/school/getSchoolVideo", {
    id: id,
  });
};

// 根据视频id获取极速学院视频信息
export const getSchoolVideoInfomation = (id) => {
  return axiosInstance.post("/api/school/getSchoolVideos", {
    id: id,
  });
};

// 获取活动
export const getActivity = () => {
  return axiosInstance.post("/api/activity/getActivity", {});
};

// 领取优惠卷
export const takeRedpack = (uid, array) => {
  return axiosInstance.post("/api/redpack/takeRedpack", {
    uid: uid,
    array: array,
  });
};

// 通过UID查询用户所有优惠卷
export const getActivityForUid = (uid) => {
  return axiosInstance.post("/api/activity/getActivityForUid", {
    uid: uid,
  });
};

// 修改邮箱
export const changeemail = (token, email, captcha) => {
  return axiosInstance.post(
    "/api/user/changeemail",
    {
      email: email,
      captcha: captcha,
    },
    {
      headers: {
        token: token,
      },
    }
  );
};

// 短信发送
export const send = (mobile, event) => {
  return axiosInstance.post("/api/sms/send", {
    mobile: mobile,
    event: event,
  });
};

// 绑定手机
export const changemobile = (token, mobile, captcha) => {
  return axiosInstance.post(
    "/api/user/changemobile",
    {
      mobile: mobile,
      captcha: captcha,
    },
    {
      headers: {
        token: token,
      },
    }
  );
};

// 获取收款绑定信息
export const getPayBind = (uid) => {
  return axiosInstance.post("/api/agent/getPayBind", {
    uid: uid,
  });
};

// 绑定收款信息
export const bindPay = (uid, account, pic, type) => {
  return axiosInstance.post("/api/agent/bindPay", {
    uid: uid,
    account: account,
    pic: pic,
    type: type,
  });
};

// 获取首页轮播图
export const getBanner = () => {
  return axiosInstance.post("/api/index/getBanner", {});
};

// 获取服务器分类列表
export const getServerCategoryList = () => {
  return axiosInstance.post("/api/index/getServerCategoryList", {});
};

// 根据id获取服务器列表
export const getServerList = (id) => {
  return axiosInstance.post("/api/index/getServerList", {
    id: id,
  });
};

// 获取产品列表
export const getProduct = () => {
  return axiosInstance.post("/api/index/getProduct", {});
};

// 根据用户UID查询可使用的优惠卷
export const getRedpackForUID = (uid) => {
  return axiosInstance.post("/api/redpack/getRedpackForUID", {
    uid: uid,
  });
};
